import { useContext } from 'react';
import Deadlines from 'components/Header/Deadlines/Deadlines';
import PhaseSelect from 'components/Header/PhaseSelect/PhaseSelect';
import useT from 'hooks/useT';
import { HeaderText } from '@groupbuilderoy/gb-components-library';
import BackButton from 'components/BackButton/BackButton';
import Progress from 'components/Progress/Progress';
import useStyles from './styles';
import useSetupSections from 'hooks/useSetupSections';
import useSetupPhases from 'hooks/useSetupPhases';
import { SectionContext } from 'context/Section/SectionContext';
import { Alert } from '@material-ui/lab';
import { ProjectContext } from 'context/Project/ProjectContext';
import { ApartmentContext } from 'context/Apartment/ApartmentContext';
import useBasePath from 'hooks/useBasePath';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import Section from 'routes/Section/Section';
import Phase from 'routes/Phase/Phase';
import CreateSection from 'routes/CreateSection/CreateSection';
import { useEditingMode } from 'hooks/useEditingMode';
import EditSection from 'routes/EditSection/EditSection';
import { Trans } from 'react-i18next';
import { Typography, useTheme } from '@material-ui/core';
import { AppContext } from 'context/App/AppContext';
import { organizationsWithExtendedHeaderText } from 'utils/utils';
import CreatePhase from 'routes/CreatePhase/CreatePhase';
import EditPhase from 'routes/EditPhase/EditPhase';
import CreateHeaderSection from 'routes/CreateSection/CreateHeaderSection';

export default function Home() {
  useSetupPhases();
  useSetupSections();
  const theme = useTheme();
  const { appState } = useContext(AppContext);
  const { projectState } = useContext(ProjectContext);
  const { apartmentState } = useContext(ApartmentContext);
  const { sectionState } = useContext(SectionContext);
  const classes = useStyles();
  const t = useT();
  const basePath = useBasePath();
  const { path } = useRouteMatch();
  const appParams = useParams<{ sectionParentId: string }>();
  const { editingModeEnabled } = useEditingMode();

  const renderContent = () => {
    const error = projectState.error || apartmentState.error || sectionState.error;
    const loading = projectState.loading || apartmentState.loading || sectionState.loading;

    if (error) {
      return (
        <Alert severity='error' variant='outlined'>
          {error}
        </Alert>
      );
    } else if (loading) return <Progress />;

    return (
      <div className={classes.content}>
        {appParams.sectionParentId !== undefined && <BackButton />}
        <div>
          <Switch>
            {editingModeEnabled && (
              <Route
                path={`${basePath}/phase/:phaseId/section/newHeader`}
                component={CreateHeaderSection}
              />
            )}
            {editingModeEnabled && (
              <Route path={`${basePath}/phase/:phaseId/section/create`} component={CreateSection} />
            )}
            {editingModeEnabled && (
              <Route
                path={`${basePath}/phase/:phaseId/section/:sectionId/edit`}
                component={EditSection}
              />
            )}
            {editingModeEnabled && (
              <Route exact path={`${basePath}/phase/:phaseId/create`} component={CreatePhase} />
            )}
            {editingModeEnabled && (
              <Route exact path={`${basePath}/phase/:phaseId/edit`} component={EditPhase} />
            )}
            <Route path={`${basePath}/phase/:phaseId/section/:sectionId`} component={Section} />
            <Route path={path} exact component={Phase} />
          </Switch>
        </div>
      </div>
    );
  };

  return (
    <div data-testid='cj3-home' className={classes.container}>
      <Deadlines />
      {sectionState.loading || sectionState.sections.find((s) => s.orderNumber === -1) ? (
        sectionState.loading ? null : (
          sectionState.sections.find((s) => s.orderNumber === -1)?.card
        )
      ) : (
        <HeaderText
          heading={t('welcomeHeader')}
          description={t('mainSubheading')}
          bold={appState.tenantSettings?.organisationId === 'hartela'}
        />
      )}
      {organizationsWithExtendedHeaderText.includes(
        appState.tenantSettings?.organisationId || ''
      ) && (
        <Typography
          style={{
            color: '#667085',
            marginTop: theme.spacing(0),
            whiteSpace: 'break-spaces'
          }}
        >
          <Trans i18nKey={t('mainSubheadingExtension')} />
        </Typography>
      )}
      {!appState.tenantSettings?.cj3PhasesInTopMenu && <PhaseSelect />}
      {renderContent()}
    </div>
  );
}
